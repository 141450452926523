import { forwardRef } from 'react';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';
import { UnavailableFeatureTooltip } from '@qb/frontend/components/UnavailableFeatureTooltip';
const disabledOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault();
};

/**
 * Will show a tooltip for certain links for demo tenants
 * Otherwise, normal link
 */
const BlockedOnDemoLink = forwardRef<HTMLAnchorElement, NextLinkProps & {
  shouldShowBlockedTooltip: boolean;
}>(function BlockedOnDemoLink({
  href,
  // removes the title from the Tooltip child:  https://github.com/mui/material-ui/issues/20014
  // @ts-expect-error - FIX THE TYPES
  title,
  shouldShowBlockedTooltip,
  ...props
}, ref) {
  if (shouldShowBlockedTooltip) {
    return <UnavailableFeatureTooltip title={title}>
        <a onClick={disabledOnClick} {...props} style={{
        cursor: 'pointer'
      }} />
      </UnavailableFeatureTooltip>;
  }
  return <NextLink ref={ref} {...props} href={href} title={title} />;
});

// eslint-disable-next-line import/no-default-export
export default BlockedOnDemoLink;