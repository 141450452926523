'use client';

import { ReactNode } from 'react';
import { usePathname } from 'next/navigation';
import { Button, ButtonProps, Typography } from '@mui/material';
export const NavbarItem = ({
  href,
  label,
  isParentActive,
  ...rest
}: ButtonProps & {
  label: ReactNode;
  isParentActive?: boolean;
}) => {
  const pathname = usePathname();
  const isActive = pathname === href || isParentActive;
  return <Button variant="text" color="tertiary" href={href} sx={{
    height: 44,
    px: 2,
    backgroundColor: isActive ? 'tertiary.4p' : undefined
  }} {...rest} data-sentry-element="Button" data-sentry-component="NavbarItem" data-sentry-source-file="NavbarItems.tsx">
      <Typography variant="h5" component="span" noWrap data-sentry-element="Typography" data-sentry-source-file="NavbarItems.tsx">
        {label}
      </Typography>
    </Button>;
};