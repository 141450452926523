'use client';

import { PropsWithChildren, ReactNode, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Container, Stack, Link, Box, IconButton, Drawer } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { NavbarItem } from '@/global/components/Header/NavbarItems';
const MOBILE_DRAWER_WIDTH = 220;
const HeaderMobile = ({
  children
}: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  return <>
      <IconButton onClick={() => setIsOpen(true)} sx={{
      color: 'text.primary'
    }} data-sentry-element="IconButton" data-sentry-source-file="Header.tsx">
        <MenuIcon sx={{
        fontSize: 28
      }} data-sentry-element="MenuIcon" data-sentry-source-file="Header.tsx" />
      </IconButton>
      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)} sx={{
      width: MOBILE_DRAWER_WIDTH,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: {
        width: MOBILE_DRAWER_WIDTH
      },
      [`& .MuiButton-root`]: {
        justifyContent: 'start',
        pl: 3
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="Header.tsx">
        <Stack data-sentry-element="Stack" data-sentry-source-file="Header.tsx">
          <Stack sx={{
          py: 1.75,
          px: 2
        }} alignItems="start" data-sentry-element="Stack" data-sentry-source-file="Header.tsx">
            <IconButton onClick={() => setIsOpen(false)} sx={{
            color: 'text.primary'
          }} data-sentry-element="IconButton" data-sentry-source-file="Header.tsx">
              <MenuOpenIcon sx={{
              fontSize: 28,
              transform: 'rotate(180deg)'
            }} data-sentry-element="MenuOpenIcon" data-sentry-source-file="Header.tsx" />
            </IconButton>
          </Stack>
          {children}
        </Stack>
      </Drawer>
    </>;
};
export type HeaderLink = {
  href: string;
  label: string;
};
type Props = PropsWithChildren<{
  logoImage?: ReactNode;
  links: HeaderLink[];
}>;
export const Header = ({
  logoImage,
  links
}: Props) => {
  const isUnderMD = useIsUnderBreakpoint('md');
  const linkElems = links.map((link, index) => <NavbarItem key={index} href={link.href} label={link.label} />);
  return <Box component="header" sx={{
    bgcolor: 'common.white',
    borderBottom: '8px solid',
    borderBottomColor: 'tertiary.dark'
  }} data-sentry-element="Box" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <Container sx={{
      py: 1.75
    }} data-sentry-element="Container" data-sentry-source-file="Header.tsx">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} pl={[1, 1, 0]} data-sentry-element="Stack" data-sentry-source-file="Header.tsx">
          <Link href="/" title="View Homepage" sx={{
          display: 'flex'
        }} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
            {logoImage}
          </Link>
          <Stack component="nav" direction="row" alignItems="center" spacing={{
          md: 2,
          lg: 4,
          xl: 6
        }} data-sentry-element="Stack" data-sentry-source-file="Header.tsx">
            {isUnderMD ? <HeaderMobile>{linkElems}</HeaderMobile> : linkElems}
          </Stack>
        </Stack>
      </Container>
    </Box>;
};