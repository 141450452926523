'use client';

import { PropsWithChildren } from 'react';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Box, Link as MuiLink, Stack, Typography } from '@mui/material';
export const StartChatLink = ({
  children
}: PropsWithChildren) => {
  const handleStartChatClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window?.salesPilotPostMessages?.openChatPage();
  };
  return <MuiLink href="#" onClick={handleStartChatClick} color="tertiary.contrastText" sx={{
    width: 'fit-content',
    mb: 1
  }} data-sentry-element="MuiLink" data-sentry-component="StartChatLink" data-sentry-source-file="StartChatLink.tsx">
      <Stack direction="row" spacing={1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="StartChatLink.tsx">
        <Box component="span" sx={{
        fontSize: 24,
        display: 'flex'
      }} data-sentry-element="Box" data-sentry-source-file="StartChatLink.tsx">
          <ChatOutlinedIcon data-sentry-element="ChatOutlinedIcon" data-sentry-source-file="StartChatLink.tsx" />
        </Box>
        <Typography component="span" variant="bodyMedium" color="inherit" data-sentry-element="Typography" data-sentry-source-file="StartChatLink.tsx">
          {children}
        </Typography>
      </Stack>
    </MuiLink>;
};